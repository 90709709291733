<template>
<div class="page-container">
  <standard-page />
</div>
</template>

<script>
import StandardPage from "./pages/StandardPage.vue";

export default {
  name: "App",
  components: {
    StandardPage,
  },
};
</script>

<style lang="scss">
@import "./styles/base.scss";
  body {
    background-color: $background-color;
    color: $font-color;
    font-family: Roboto, sans-serif; 
  }
</style>
