<template>
  <div class="characters-container">
      <charactersContainer :characters="characters"/>
  </div>
</template>

<script>
import CharactersContainer from "../components/CharactersContainer.vue"

export default {
  name: "StandardPage",
  components: {
    CharactersContainer
  },
  data: () => ({
    characters: []
  }),
  async mounted() {
    if(localStorage.characters) {
      this.characters = JSON.parse(localStorage.characters);
    }

    // Code pour rattraper les données avec l'ancienne méthode de stockage
    if(this.characters.length <= 1 && localStorage.character) {
      let newCharacter = JSON.parse(localStorage.character);
      
      // On récupère le serveur s'il est persisté
      if(localStorage.server) {
        newCharacter.server = localStorage.server;
      }

      this.characters.push(newCharacter);

      // On clean le storage
      localStorage.removeItem('characterName');
      localStorage.removeItem('server');
      localStorage.removeItem('characterImage');
      localStorage.removeItem('character');
    }
  },
  watch: {   
    characters: {
      handler(newCharacters) {
      localStorage.characters = JSON.stringify(newCharacters);
      },
      deep: true
    }
  }
};
</script>
