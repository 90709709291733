<template>
  <div class="command-container">
    <span class="command-text">{{command.label}} ({{command.command}})</span>
    <span class="command-action" @click="rollDice">🎲</span>
    <span class="command-action" @click="removeCommand">❌</span>
  </div>
</template>

<script>

export default {
  name: 'Command',
  props: {
    command: Object
  },
  methods: {
    rollDice: function() {
      this.$emit('roll', this.command)
    },
    removeCommand: function() {
      this.$emit('delete', this.command);
    }
  }
}
</script>

<style lang="scss" scoped>
.command {
  &-container {
    margin: 8px 0;
  }

  &-text {

  }

  &-action {
    margin: 0 4px;
    cursor: pointer;
    & + & {
      margin: 0;
    }
  }
}
</style>