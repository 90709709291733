<template>
  <span class="input-group">
    <input class="input-text" type="text" :placeholder="label" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)"/>
    <label class="input-label" :class="{hidden: isHidden}">{{label}}</label>
  </span>
</template>

<script>
export default {
  name: 'Input', 
  props: {
    label: String,
    modelValue: String
  },
  computed: {
    isHidden: vm => vm.modelValue == ""
  }
}
</script>


<style lang="scss" scoped>
  @import "../styles/base.scss";

  .input {
    &-group {
      display: inline-block;
      position: relative;
      min-width: 400px;
      height: 30px;    
      // margin-top: 6px;
      margin-bottom: 12px;

    }

    &-label {
        top: 0px;
        left: 0px;
        position: absolute;
        font-size: 12px;
    }

    &-text {
      background: none;
      border: none;
      color: $font-color;
      width: 100%;
      height:100%;    
      outline: none;
      margin-top: 5px;

      &:focus {
        border-bottom: 2px solid $font-color;
      }
    }
  }
</style>