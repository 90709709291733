<template>
  <div class="character-container">
    
    <div class="character-delete" @click="$emit('deleteCharacter',$event)">
      ❌
    </div>

    <v-input label="Nom du personnage" v-model="tempCharacter.name"/>
    <v-input label="Image du personnage" v-model="tempCharacter.image"/>
    
    <div class="commands-container">
        <command v-for="c in character.commands" :key="c.label" :command="c" @roll="$emit('roll',$event)" @delete="$emit('deleteCommand',$event)"/>
    </div>
    
    <div>  
      <v-input label="Libellé de la commande" v-model="customCommand.label"/>
      <v-input label="Commande" v-model="customCommand.command"/>
      <button @click="addCommand">Ajouter</button>
    </div>

    <div>
      <v-input label="Commande personnalisée" v-model="tempCommand" />
          <button @click="sendCustom">Roll the dice !!!</button>
    </div>  
  </div>
</template>

<script>
import VInput from "../components/Input.vue";
import Command from "../components/Command.vue";

export default {
  name: "Character",
  components: {
    VInput,
    Command,
  },
  props:{
    character: {
      type: Object,
      default: () => ({
        name: "Test",
        image: "Hello",
        commands: []
      })
    }
  },
  data: function () {
    return {
      customCommand: {
        label: "",
        command: ""
      },
      tempCommand: ""
    }
  },
  computed: {
    tempCharacter: function () {
      return this.character;
    }
  },
  methods: {  
    addCommand: function() {
      this.$emit('addCommand', this.customCommand);
      this.customCommand = {
        label: "",
        command: ""
      }
    },
    sendCustom: function() {
      this.$emit('sendCustom', this.tempCommand);
    }
  },
};
</script>

<style lang="scss" scoped>
  .character-container {
    position: relative;
  }


  .character-delete {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }

</style>