<template>
  <div class="servers-container">
    <label>
      Liste des serveurs disponibles :
      <select v-model="currentCharacter.server">
        <option v-for="s in servers" :key="s">{{ s }}</option>
      </select>
    </label>
  </div>
  <div class="dice-container">
      <Die :face="100" @roll="rollDice" />
      <Die :face="20" @roll="rollDice" />
      <Die :face="12" @roll="rollDice" />
      <Die :face="10" @roll="rollDice" />
      <Die :face="8" @roll="rollDice" />
      <Die :face="6" @roll="rollDice" />
      <Die :face="4" @roll="rollDice" />
  </div> 

  <div class="characters-container">
    <div class="characters-tabs">
      <div class="character-tab" v-for="(c, i) in characters" :key="c.name" @click="changeActive(i)">
        <div v-if="c.image" class="character-tab-element character-tab-img" :class="{'active-character': activeCharacterIndex == i}" :style="'--background:url(\''+ c.image +'\')'"/>
        <div v-else class="character-tab-element character-tab-text" :class="{'active-character': activeCharacterIndex == i}">
          <span>{{ c.name ? (c.name).match(/\b\w/g).join('').toUpperCase() : 'NP'}}</span>
        </div>
      </div>      
      <div class="character-tab" @click="addCharacter">
        <div class="character-tab-add">
            +
        </div>
      </div>
    </div>
    
    <div class="active-character">
      <character :character="currentCharacter" @roll="rollCommand" @deleteCommand="deleteCommand" @addCommand="addCommand" @deleteCharacter="deleteCharacter" @sendCustom="sendCustom"/>
    </div>
  </div>
</template>

<script>
import Character from "../components/Character.vue";
import Die from "../components/Die.vue";

export default 
{
  name: 'CharactersContainer',
  components: {
    Character,
    Die,
  },
  props: {
    characters: Array
  },
  data: () => ({
    server: "",
    servers: [],
    activeCharacterIndex: -1
  }),
  async mounted() {
    this.servers = (
      await this.$http.get('/actions/servers')
    ).data;

    // Si on a aucun personnage, on ajoute un personnage vide
    if(this.characters.length == 0) {
      this.addCharacter();
    }

    // On récupère le dernier personnage utilisé
    if(localStorage.activeCharacterIndex) {
      this.activeCharacterIndex = localStorage.activeCharacterIndex;
    } else {
      this.activeCharacterIndex = 0;
    }
  },
  computed: {
    currentCharacter: function () {      
      if(this.activeCharacterIndex == -1) {
        return {
          name:'',
          commands: [],
          server: '',
          image: ''
        }
      }
      return this.characters[this.activeCharacterIndex];
    },
    _characters: function() {
      return this.characters;
    }
  },
  watch: {
    activeCharacterIndex: {
      handler(newActiveCharacterIndex) {
        localStorage.activeCharacterIndex = newActiveCharacterIndex;
        this.server = this.characters[newActiveCharacterIndex].server
      }
    }
  },
  methods: {
    rollDice: function(command, commandDescription) {
      // Intercepteur TaleSpire
      if(this.currentCharacter.server == 'TaleSpire') {
        let url = `talespire://dice/${commandDescription ? commandDescription : ''}:${command}`
        window.open(url);
        return;
      } 
      else {
        this.$http.post('/actions', {
          destination: this.currentCharacter.server,
          diceCommand: command,
          diceCommandDescription: commandDescription,
          characterName: this.currentCharacter.name,
          characterImage: this.currentCharacter.image,
        });
      }
    },
    rollCommand: function(command) {
      this.rollDice(command.command, command.label);
    },
    sendCustom: function(command) {
      this.rollDice(command);
    },
    addCommand: function(customCommand) {
      this.currentCharacter.commands.push(customCommand);
    },
    deleteCommand: function(c) {
      if(!window.confirm("Sûr(e) ?")) return;
     var indexToRemove = this.currentCharacter.commands.map(e => e.label).indexOf(c.label);
     this.currentCharacter.commands.splice(indexToRemove, 1);
    },
    changeActive(index) {
      this.activeCharacterIndex = index;
    },
    addCharacter() {
      this._characters.push({
          name:'',
          commands: [],
          server: '',
          image: ''
        });
        
      this.activeCharacterIndex = this._characters.length - 1;
    },
    deleteCharacter() {      
      if(!window.confirm("Sûr(e) ?")) return;
      
      if(this.activeCharacterIndex >= 0) {
        this._characters.splice(this.activeCharacterIndex, 1);
      } 

      // Si on supprime le dernier personnage, on en recréé un par défaut
      if(this._characters.length == 0) {
        this.addCharacter();
      }

      // On se replace sur le premier personnage
      this.activeCharacterIndex = 0;
    }
  },
}
</script>

<style lang="scss" scoped>
  .dice-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }

  .characters-container {
    display: flex;
    width: 100vw;

    .characters-tabs {
      margin:8px;
      margin-left:0px;      
      border-right:1px solid #efefef;          

      .character-tab {
        padding:8px;    
        padding-right: 16px;
        height: 8vh;
        width: 8vh; 
        border-radius: 50%;  

        &-element, &-add { 
          height: 100%;
          width: 100%; 
          border-radius: 50%;    
          background-size: 100%;
          background-repeat: no-repeat;
          background-position: center;
          border: 3px solid #333333;     
          cursor: pointer;   
          color:#3ba55d;  
          text-align: center; 
          line-height: 8vh;
          vertical-align: middle;  
          font-size: 3vh;  
        }

        &-add {
          border: 3px solid #3ba55d;
          font-size: 6vh;  
        }  

        &-img {            
          background-image: var(--background);
        }

        .active-character {
          border: 3px solid #3ba55d;
        }

       
      }
    }
  }
</style>