<template>
  <div :class="dieClassContainer">
    <div @click="rollDice" :class="dieClass">{{ face }}</div>
  </div>
</template>

<script>
export default {
  name: 'Die',
  props: {
    face: Number
  },
  computed:{
    dieClass: function () {
      return "die die-" + this.face;
    },
    dieClassContainer: function () {
      return "die-container die-" + this.face;
    }
  },
  methods: {
    rollDice: function()  {
      this.$emit('roll', `1d${this.face}`)
    }
  }
}
</script>

<style lang="scss" scoped>
  .die-container {
    display: inline-block;
    width: 50px;
    height: 50px;    
    line-height: 50px;    
    text-align: center;
    vertical-align: middle;
    
    margin: 16px auto;

    .die {
      font-size: 20px;
      color: white;

      &-4 {
        background: green;
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      }
      &-6 {
        background: blueviolet;
      }
      &-8 {
        background: red;
        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
      }
      &-10, &-100 {
        background: blue;
      }
      &-12 {
        background: orchid;
        clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
      }
      &-20 {
        background: darkmagenta;
        clip-path: polygon(50% 0%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0% 43%, 17% 12%);
      }
    }
  }
  
</style>